<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */

/** IMPORT COMPONENTS */
import Button from "@/components/regularComponents/UIComponents/Button.vue";
const Picture = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Picture.vue")
);
/** IMPORT TYPES */
import type { ListDataInterface } from "@/types/ContentType";

import { useMainStore } from "@/store/main/index";

import type { IndxInterface } from "@/store/main/types/StateType";

const mainStore = useMainStore();

/** PROPS */
defineProps<{
  slide?: ListDataInterface;
}>();

const indx = useState<IndxInterface>("data");

/** GET PROJECT LINK, RETURN '' IF NO SINGLE ROUTE FOUND */
const getProjectLink = (slide: ListDataInterface | undefined): string | undefined => {
  if (!slide?.singlePageRoute) return "";
  const matchedMenu = indx.value?.menus?.find(
    (menu) => menu.id === Number(slide.singlePageRoute)
  );
  return matchedMenu?.full_url || "";
};
</script>

<template>
  <router-link class="projects-slider-card pos-rel ov-hidden" :to="getProjectLink(slide)">
    <Picture
      :devices="slide?.image?.[0]?.devices"
      imgClass="img-absolute"
      :alt="slide?.title"
      :isLazy="false"
    />
    <div
      class="projects-slider-card__text-box d-flex dir-col al-center j-center pos-abs no-flicker"
    >
      <h2 class="hl-3-bold no-flicker c-white">
        {{ slide?.title }}
      </h2>
      <p class="p-14 no-flicker c-white">
        {{ slide?.teaser }}
      </p>
      <Button
        v-if="getProjectLink(slide)"
        :btnType="3"
        :btnText="'choose apartment'"
        :noBorder="true"
        :uppercase="true"
      ></Button>
    </div>
  </router-link>
</template>
<style lang="scss">
.projects-slider-card {
  width: 956px;
  height: calc(100vh - 610px);
  transform: translateX(100%);
  opacity: 0;
  @include easeOut(800, all);

  img {
    @include easeOut(1200ms, all);
  }
  &__text-box {
    top: 0;
    left: 0;
    opacity: 0;
    @include easeOut(500ms, all);
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;

    h2 {
      @include weight(600);
      width: 100%;
    }
    p {
      margin-top: 24px;
      @include weight(500);
      @include clamp(3);
    }
    .button {
      position: absolute;
      left: 50%;
      bottom: 0;
      white-space: nowrap;
      transform: translate(-50%, 0);
      @include easeOut(500ms, all);

      @include maxq(mobile) {
        &::after {
          content: none;
        }
      }
    }
  }

  @include minq(desktop-sm) {
    @include hover {
      img {
        transform: scale(1.3);
        filter: blur(5px);
      }
      .projects-slider-card__text-box {
        opacity: 1;
        .button {
          transform: translate(-50%, -112px);
          @include maxq(desktop-2k) {
            transform: translate(-50%, -80px);
          }
          @include maxq(desktop) {
            transform: translate(-50%, -35px);
          }
        }
      }
    }
  }
  .projects-slider-card__text-box {
    @include maxq(desktop-sm) {
      justify-content: end;
      p {
        @include clamp(2);
        line-height: 20px;
        margin-bottom: 114px;
      }
      opacity: 1;
      .button {
        transform: translate(-50%, -112px);
        @include maxq(desktop-2k) {
          transform: translate(-50%, -80px);
        }
        @include maxq(desktop) {
          transform: translate(-50%, -35px);
        }
      }
      .button svg {
        display: none;
      }
    }
    @include maxq(tablet) {
      h2 {
        font-size: 20px;
        line-height: 24px;
      }
      p {
        @include clamp(3);
        margin-bottom: 90px;
      }
      .button--3 {
        height: 46px;
      }
      .button--3 span {
        font-size: 14px;
        line-height: 22px;
        margin-left: initial;
      }
    }
    @include maxq(mobile) {
      p {
        margin-bottom: 93px;
        font-size: 12px;
        line-height: 20px;
      }
    }
    @include maxq(mobile-sm) {
      .button--3 span {
        font-size: 10px;
      }
    }
  }

  &:not(:last-child) {
    margin-right: 72px;
  }

  @include maxq(desktop-2k) {
    height: calc(100vh - 409px);
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
  @include maxq(desktop-lg) {
    height: calc(100vh - 307px);
    &:not(:last-child) {
      margin-right: 36px;
    }
    &__text-box {
      padding-left: 30px;
      padding-right: 30px;
    }
    &:not(:last-child) {
      margin-right: 36px;
    }
    p {
      margin-top: 8px;
    }
  }
  @include maxq(desktop) {
    height: calc(100vh - 242px);

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  @include maxq(desktop-sm) {
    height: calc(100vh - 216px);
  }

  @include maxq(tablet) {
    height: 552px;
    width: 344px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  @include maxq(mobile) {
    width: 100%;
    height: 0;
    padding-top: calc(100% / 0.62268518518);
  }
  @include maxq(mobile-sm) {
    &:not(:last-child) {
      margin-right: initial;
    }
  }
}
</style>
